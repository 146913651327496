import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Form,Button } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';

import im1 from '../images/bubble-7.png';
import im2 from '../images/line-4.png';
import im3 from '../images/line-5.png';

const StyledDIV = styled.div`
background-color:#ecf2f6 ;
margin-top: 100px;
.section-heading .subtitle {
    color:#6c0fd8;
    font-weight: 800;
    font-size: 20px;
}
 .section-heading .title {
    letter-spacing: -0.045em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    padding-bottom: 5px;
    font-size:64px;
    margin-top:15px;
}
 .section-heading p {
    width: 100%;
    font-size: 18px;
    color: #535361;
    font-weight: 500;
}


.heading-left p {
    margin: 40px 0 30px;
}
.contact-form-box {
    background-color: #fff;
    padding: 50px;
    border-radius: 30px;
    margin: 0 30px;
    position: relative;
    z-index: 1;
}
.section_di{
    padding: 200px 0 80px;
    position: relative;
    overflow: hidden;
}
input, .form-control {
    font-size: 15px;
}
input, .form-control {
    border: 1px solid #ECF2F6;
    border-radius: 16px;
    background-color: transparent;
    color: #292930;
    padding: 15px 20px;
    width: 100%;
}
.btn_ajx Button{
    width: 100%;
    border: 1px solid #99a1aa;
    color: #27272e;
    background-color: transparent;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.04em;
    padding: 18px 55px;
    height: auto;
    text-align: center;
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 1;
}

.btn_ajx Button:hover {
    background-color: #6c0fd8;
    color: #fff;
    border-color:#6c0fd8;
}
input:focus, .form-control:focus {
    outline: none;
    box-shadow: 0 20px 48px -14px rgb(153 161 170 / 25%);
    border-color: transparent;
}
.contact-form-box .title {
    font-size: 40px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
}
.form-label  {
    font-size: 15px;
    font-weight: 700;
    color: #27272e;
    margin-bottom: 10px;
}
.btn_ajx {
    padding-top: 30px;
}
.shape-group-6 .shape {
    position: absolute;
    z-index: 0;
}
.shape-group-6 .shape.shape-1 {
    top: 0;
    left: 0;
}
.section-heading.heading-left {
    position: relative;
    z-index: 1;
}
.shape-group-6 .shape.shape-2 {
    top: 110px;
    left: 0;
}
.shape-group-6 .shape.shape-3 {
    bottom: 140px;
    right: 0;
}
@media only screen and (max-width: 1388px) {
.contact-form-box {
    margin: 0 10px;
}

}
@media only screen and (max-width: 500px) {
.contact-form-box {
    margin: 0 10px;
}
.section-heading .title{
    font-size: 44px;
}
.contact-form-box .title {
    font-size: 20px;
}
}

`;
const SectionDi = () => {
    return <StyledDIV id="About">

        <div className='section_di'>
            <Container>
                <Row>
                    <Col lg={6}>
                    <Fade bottom>
                        <div className="section-heading heading-left">
                            <span className="subtitle">Pricing</span>
                            <h2 className="title">Get the best services at great prices.</h2>
                            <p>To obtain our offers, please fill in the following data in order to ensure that the offers reach your personal email. Or you can also contact us directly via e-mail or phone number at the bottom of the site.</p>

                            <p>By subscribing to us, you will receive our new offers exclusively via your personal email</p>
                        </div>
                        </Fade>
                    </Col>
            
                    <Col xl={{span:5,offset:1}} lg={6}>
                  
                        <div class="contact-form-box">
                            <h2 class="title">Get Offers</h2>
                            <Form>
                                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Your name" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="exampleForm.ControlInput2">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Your Email" />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="exampleForm.ControlInput2">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control type="text" placeholder="Your Phone" />
                                </Form.Group>
                                <div className="mb-4 btn_ajx"><Button variant="primary" type="submit">Get Pricing Now</Button></div>
                            </Form>
                        
                        </div>
                        
                    </Col>
                </Row>


            </Container>
            <ul className="shape-group-6 list-unstyled">
                            <li className="shape shape-1"><img loading="lazy"  src={im1} alt="Bubble"/></li>
              
                            <li className="shape shape-2"><img loading="lazy"  src={im2} alt="Bubble"/></li>
              
                            <li className="shape shape-3"><img loading="lazy"  src={im3} alt="Bubble"/></li>
              
        </ul>
        </div>

    </StyledDIV>;
};

export default SectionDi;
