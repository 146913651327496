import React from 'react';
import styled from 'styled-components';
import { Container,Row,Col } from 'react-bootstrap'; 
import im1 from '../images/f1.jpg';


const StyledDIV = styled.div`
margin-top: 100px;
.section-heading .subtitle {
    color:#6c0fd8;
    font-weight: 800;
    font-size: 20px;
}
 .section-heading .title {
    letter-spacing: -0.045em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    padding-bottom: 5px;
    font-size:64px;
    margin-top:15px;
}
 .section-heading p {
    width: 100%;
    font-size: 18px;
    color: #535361;
    font-weight: 500;
}


.heading-left p {
    margin: 0 0 30px;
}
.axil-btn {
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.04em;
    padding: 18px 55px;
    height: auto;
    text-align: center;
    transition: all 0.3s ease-in-out;
    position: absolute;
    z-index: 1;
}
.axil-btn {
    box-shadow: 1px 20px 20px -24px #6c0fd8;
}
.axil-btn.btn-fill-primary {
    background-color: #6c0fd8;
    color: #fff;
    border: 1px solid #6c0fd8;
    overflow: hidden;
}
.axil-btn.btn-fill-primary:hover {
    background-color: #5500b7;
    border-color: #5500b7;
}
.axil-btn.btn-fill-primary::after {
    content: "";
    height: 300px;
    width: 300px;
    background-color: #5500b7;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0);
    transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    z-index: -1;
}
.axil-btn.btn-fill-primary:hover::after {
    transform: translateY(-50%) translateX(-50%) scale(1);
}
.conterd{
    align-items: center;
}
.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #6c0fd8;
}

@media only screen and (max-width: 1000px) {
    .section-heading .title {
    font-size: 55px;
    }



}
@media only screen and (max-width: 500px) {
    .section-heading .title {
    font-size: 44px;
    }
    span.subtitle{
    font-size: 22px;
    width: 100%;
    }
    .case-study-featured-thumb img {
        width: -webkit-fill-available;
    margin-top: 100px;
}

}

.section_Dj .case-study-featured-thumb img {
    border-radius: 25px;
    max-width: 530px;
}

`;

const SectionDj = () => {
  return <StyledDIV id="Featured"> 
      <div className='section_Dj'>
      <Container>

          <Row className='conterd'>
              <Col>
              <div className="section-heading heading-left">                                           
                    <span className="subtitle">About Us</span>
                    <h2 className="title"> About our team</h2>
                    <p>We're a great company and we do great things. You can say that with our wonderful services. We're here for you. We make websites, apps, other software, basically everything. We design brands and create everything necessary to manage and enhance your business.</p>
                    {/*<a className="axil-btn btn-fill-primary btn-large">Read Case Study</a>*/}                                    
                </div>
              </Col>
              <Col>
              <div class="case-study-featured-thumb">
            <img loading="lazy"  src={im1} title="case-study-4" alt="case-study-4"/>                                
            </div>
              </Col>
          </Row>

          

      </Container>
      </div>
  </StyledDIV>;
};

export default SectionDj;
