import React from 'react';
import styled from 'styled-components';
import { Container,Row,Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';

import im1 from '../images/Other 07.png';
import im2 from '../images/Other 17.png';
import im3 from '../images/Other 14.png';
import im4 from '../images/Other 13.png';
import im5 from '../images/Other 12.png';
import im6 from '../images/Other 06.png';

import im8 from '../images/line-9.png';
import im9 from '../images/bubble-42.png';
import im10 from '../images/bubble-43.png';

const StyledDIV = styled.div`
background-color:#27272e;
padding: 140px 0 60px;
.section-heading .subtitle {
    color: #ffffff;
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 15px;
}
.title {
    margin-bottom: 35px;
    font-weight: 800;
    font-size: 64px;
    max-width: 528px;
    color: #fff;
}
.heading-light-left p {
    color: #ffffff;
    margin: 0 0 40px;
    font-size: 20px;
    width: 40%;
    opacity: 0.6;
}

.style_one_ser {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.style_one_ser .des {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.des h4 {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
}
 .des p {
    color: #a2a2a5;
    margin: 0 0 40px 0;
}
.des a {
    color: #fff !important;
    transition : all 0.3s ease-in-out;
    position: relative;
    font-size: 18px;
    font-weight: 500;
}
.style_one_ser {
    padding: 40px 10px;
    margin: 10px 0;
    
}
.icon img {
    width: 80px;
    margin: 0 20px;
}
.style_one_ser:hover {
    background: linear-gradient(
180deg
, #27272E 0%, #303035 100%);
    border-image-source: linear-gradient( 
0deg
 , #27272E 64.45%, rgba(74, 75, 86, 0.72) 100%);
    border-radius: 20px;
}
.style_one_ser:hover .des a {
    color: #9137fb  !important;
    padding-left: 40px;
 
}

.style_one_ser:hover .des a:before{
    width: 24px;
}
.des a:before {
    content: "";
    width: 0;
    height: 1px;
    background-color: #9137fb  ;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition : all 0.3s ease-in-out;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
}
.shape-group-10 .shape {
    position: absolute;
    z-index: -1;
}
.all_section_df{
    position: relative;
    z-index: 1;
}
.shape-group-10 .shape.shape-1 {
    top: 0px;
    right: 0;
}
.shape-group-10 .shape.shape-2 {
    bottom: 15px;
    left: 0;
}
.shape-group-10 .shape.shape-3 {
    top: 480px;
    left: 110px;
}



@media only screen and (max-width: 1000px) {
    .title {
    font-size: 55px;
}
.heading-light-left p {
    width: 100%;
}

}
@media only screen and (max-width: 500px) {
    .title {
    font-size: 44px;
    }
    .heading-light-left p{
    font-size: 22px;
    width: 100%;
    }


}
`;

export const SectionDm = () => {

  return <StyledDIV id="Services">
      <div className='all_section_df' >
          <Container>
              <div className="section-heading heading-light-left">            
                <span className="subtitle">What We Can Do For You</span>
                <h2 className="title">Services we can help you with</h2> 
                <p>In vel varius turpis, non dictum sem. Aenean in efficitur ipsum, in egestas ipsum. Mauris in mi ac tellus.</p>
                </div>

                <div className='section_services_d'>
                    <Row>
                       
                        <Col sm={12} md={6} lg={4}>
                            <Fade bottom >
                            <div className='style_one_ser'>
                                <div className='icon'>
                                    <img loading="lazy" src={im1} alt=""/>
                                </div>
                                <div className='des'>
                                    <h4>Website Development</h4>
                                    <p>We design and develop websites with great professionalism and high quality.</p>
                                    <a> Find out more</a>

                                </div>

                            </div>
                            </Fade>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                        <Fade bottom >
                        <div className='style_one_ser'>
                                <div className='icon'>
                                    <img loading="lazy"  src={im2} alt=""/>
                                </div>
                                <div className='des'>
                                    <h4>Mobile app development</h4>
                                    <p>We design and develop customized Android / iOS applications with professionalism and mastery.</p>
                                    <a> Find out more</a>

                                </div>

                            </div>
                            </Fade>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                        <Fade bottom >
                        <div className='style_one_ser'>
                                <div className='icon'>
                                    <img loading="lazy"  src={im3} alt=""/>
                                </div>
                                <div className='des'>
                                    <h4>Graphic Design</h4>
                                    <p>We create modern designs for visual identities, logos and everything related to graphic design</p>
                                    <a> Find out more</a>

                                </div>

                            </div>
                            </Fade>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                        <Fade bottom >
                        <div className='style_one_ser'>
                                <div className='icon'>
                                    <img loading="lazy"  src={im4} alt=""/>
                                </div>
                                <div className='des'>
                                    <h4>Digital Marketing</h4>
                                    <p>We do marketing and advertising for most businesses to attract the largest number of customers</p>
                                    <a> Find out more</a>

                                </div>

                            </div>
                            </Fade>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                        <Fade bottom >
                        <div className='style_one_ser'>
                                <div className='icon'>
                                    <img loading="lazy"  src={im5} alt=""/>
                                </div>
                                <div className='des'>
                                    <h4>Server Services</h4>
                                    <p>We fix all server-related errors and glitches and improve server work.</p>
                                    <a> Find out more</a>

                                </div>

                            </div>
                            </Fade>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                        <Fade bottom >
                        <div className='style_one_ser'>
                                <div className='icon'>
                                    <img loading="lazy" src={im6} alt=""/>
                                </div>
                                <div className='des'>
                                    <h4>Web Hosting</h4>
                                    <p>We publish websites on the Internet with high accuracy and attractive prices.</p>
                                    <a> Find out more</a>

                                </div>

                            </div>
                            </Fade>
                        </Col>
                    </Row>

                </div>


          </Container>
          <ul className="list-unstyled shape-group-10">
                         
                         <li className="shape shape-1"><img loading="lazy"  src={im8} alt=""/></li>
                      
                        
                         <li className="shape shape-2"><img loading="lazy"  src={im9} alt=""/></li>
                      
                        
                         <li className="shape shape-3"><img loading="lazy"  src={im10} alt=""/></li>
                                 
                 </ul>
      </div>
     
  </StyledDIV>;
};
