import React,{useState} from 'react';
import { Container,Button, Form,Row,Col,Alert } from 'react-bootstrap';
import styled from 'styled-components';
import axios from 'axios';
import chat_group from '../images/chat-group.png';
import laptop_poses from '../images/laptop-poses.png';
import bill_pay from '../images/bill-pay.png';



const StyledDIV = styled.div`
.call-to-action {
    border-bottom: 1px solid #e7e7e7;
}
.call-to-action .section-heading {
    margin-bottom: 65px;
}
.section-heading {
    text-align: center;
    margin-bottom: 60px;
}
.call-to-action .section-heading span {
    color: var(--color-accent1);
}
.section-heading .subtitle {
    color: #fff !important;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 15px;
}
.call-to-action .section-heading .title {
    width: 60%;
    margin: 10px auto 55px;
}
.heading-light .title {
     color: #fff;
    font-size: 64px;
}
.axil-btn.btn-large {
    padding: 23px 55px;
}
.axil-btn.btn-fill-white {
    background-color: #fff;
    box-shadow: 0px 48px 48px 0px rgb(0 0 0 / 12%);
}
form {
    text-align: start;
    color: #f6f8fa;
}
.axil-btn {
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -.04em;
    padding: 18px 55px;
    height: auto;
    text-align: center;
    transition: var(--transition);
    position: relative;
    z-index: 1;
    color: #292931;
}
.call-to-action .thumbnail {
    position: relative;
    text-align: center;

}
.call-to-action .thumbnail .larg-thumb {
    display: inline-block;
    margin-right: -180px;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
}
.call-to-action .thumbnail .small-thumb li.shape-1 {
    bottom: 0;
    left: 155px;
}
.call-to-action .thumbnail .small-thumb li {
    position: absolute;
}
.call-to-action .thumbnail .small-thumb li.shape-2 {
    bottom: 0;
    right: 80px;
}
.call-to-action-area:before {
    content: "";
    height: 75%;

    width: 100%;
    background-color: #27272e;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
}
.call-to-action-area {
  position: relative;
    padding: 80px 0;
    overflow: hidden;
}
.shape-group-9 .shape {
    position: absolute;
    z-index: -1;
}
.list-unstyled li {
    margin-top: 0;
    margin-bottom: 0;
}
.shape-group-9 .shape.shape-1 {
    top: 0;
    left: 32%;
}
.shape-group-9 .shape.shape-2 {
    top: 180px;
    left: 18%;
    opacity: .5;
    animation: movingleftright1 infinite 20s;
}
.shape-group-9 .shape.shape-3 {
    top: 31%;
    left: 20%;
    animation: movingleftright2 infinite 25s;
}
.shape-group-9 .shape.shape-4 {
    top: 44%;
    left: 28%;
    animation: movingleftright1 infinite 25s;
}
.shape-group-9 .shape.shape-5 {
    top: 50%;
    left: 17%;
    animation: movingleftright2 infinite 30s;
}
.shape-group-9 .shape.shape-6 {
    top: 26%;
    right: 21%;
    animation: movingleftright3 infinite 30s;
}
.shape-group-9 .shape.shape-7 {
    top: 30%;
    right: 15%;
    animation: movingleftright1 infinite 30s;
}

li.shape.shape-4,li.shape.shape-6 {
    display: none;
}

@media only screen and (max-width: 500px) {
    .heading-light .title{
        font-size: 35px;
        width: 100% !important;
    }
    .call-to-action .thumbnail .larg-thumb {
    margin-right: 0;
}
}
@media only screen and (max-width: 991px){
  .call-to-action .thumbnail .larg-thumb {
    margin-right: -130px;
}
.call-to-action .thumbnail .larg-thumb img {
    width: 70%;
}
.call-to-action .thumbnail .small-thumb li.shape-1 {
    left: -50px;
}
.call-to-action .thumbnail .small-thumb li img {
    width: 70%;
}
.call-to-action .thumbnail .small-thumb li.shape-2 {
    right: -95px;
}
}
@media only screen and (max-width: 767px){
  .call-to-action .thumbnail .larg-thumb {
    margin-right: -100px;
}
.call-to-action .thumbnail .larg-thumb img {
    width: 50%;
}
.call-to-action .thumbnail .small-thumb li.shape-1 {
    left: -70px;
}
.call-to-action .thumbnail .small-thumb li img {
    width: 35%;
}
.call-to-action .thumbnail .small-thumb li.shape-2 {
    right: -110px;
}
}
@media only screen and (max-width: 575px){
  .call-to-action .thumbnail .larg-thumb {
    margin-right: -50px;
}
.call-to-action .thumbnail .larg-thumb img {
    width: 50%;
}
.call-to-action .thumbnail .small-thumb li.shape-1 {
    left: -125px;
}
.call-to-action .thumbnail .small-thumb li.shape-2 {
    right: -130px;
}
}
.titil_des_section {
    color: #f6f8fa;
}
input,select {
    height: 50px;
    border-radius: 15px;
}
textarea{
    border-radius: 15px;
}


.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #6c0fd8;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(108 15 216 / 22%);
}
.form-select:focus {
    border-color: #6c0fd8;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(108 15 216 / 27%);
}
h3.titel_about {
    margin-bottom: 40px;
    font-size: 32px;
}
.dfss.row button {
    width: 440px;
    background-color: #6c0fd8;
    border: none;
    height: 50px;
    border-radius: 15px;
    margin-top: 24px;
}
.dfss.row {
    text-align: center;
}
label.form-label {
    color: #f6f8faab;
}
.fade.alert.alert-success.show {
    width: -webkit-fill-available;
    margin: 0 40px;
    border-radius: 15px;
}
@media only screen and (max-width: 500px) {
.dfss.row button {
    width: 200px;
    background-color: #6c0fd8;
    border: none;
    height: 50px;
    border-radius: 15px;
    margin-top: 10px;
}  
form {
    padding-bottom: 60px;
}
}

`;

const SectionFFf = () => {

    const [validated, setValidated] = useState(false);
    const [finished,setfinished] =  useState(false);
    const [data_form , setdata_form] = useState({
        email:'',
        name:'',
        phone:'',
        type:'Logo',
        contante:''
        
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
       if (form.checkValidity() !== false) {
            setValidated(true);
            axios.post(`https://next.codany.net/email_sand.php`,JSON.stringify(data_form),{
                headers: {
                  'Content-Type': 'application/json'
                }
              })
      .then(res => {
        console.log(res.data['state']);
        if(res.data['state']){
            setfinished(true);
        }
      });
            console.log(data_form);
        }
        
      };
  return (
    <StyledDIV>
      <div className='SectionFF'>
        <div className='class="section call-to-action-area has-image"'>
          <Container>
            <div className="call-to-action">
              <div className="section-heading heading-light">

              <div className="titil_des_section">

            </div>
            <Form className='form_contect'  validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Col sm={6}>
                        <Form.Group className="mb-3" controlId="formBasicname">
                            <Form.Label>Full Name *</Form.Label>
                            <Form.Control  onChange={e => setdata_form({ ...data_form,name:e.target.value})} value={data_form.name} required type="text" />
                            <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                    <Form.Group className="mb-3" controlId="formBasicemail">
                            <Form.Label>Email *</Form.Label>
                            <Form.Control   onChange={e => setdata_form({ ...data_form,email:e.target.value})} value={data_form.email}  required  type="email" />
                            <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                    <Form.Group className="mb-3" controlId="formBasicphone">
                            <Form.Label>Phone *</Form.Label>
                            <Form.Control   onChange={e => setdata_form({ ...data_form,phone:e.target.value})} value={data_form.phone} required type="text" />
                            <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                    <Form.Group className="mb-3" controlId="formBasicSujet">
                            <Form.Label>Type *</Form.Label>
                            <Form.Select defaultValue={data_form.type}  onChange={e => setdata_form({ ...data_form,type:e.target.value})} aria-label="Default select example">
                                <option value="Logo">Logo</option>
                                <option value="Brand">Brand</option>
                                <option value="Website">Website</option>
                                <option value="Application">Application</option>
                                <option value="Application + Website">Application + Website</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={12}>
                    <Form.Group className="mb-3" controlId="formBasicMessage">
                        <Form.Label>Talk about your Project</Form.Label>
                        <Form.Control as="textarea" onChange={e => setdata_form({ ...data_form,contante:e.target.value})}  value={data_form.contante} rows={12} />
                    </Form.Group>
                    </Col>
                </Row>
                <div className='dfss row'>
                    {finished? <Alert show={true} variant="success"><h3>Send successfully</h3> </Alert>:<div><Button className='btn_ds' type="submit">Send</Button></div>}
              </div>
            </Form>

              </div>
              <div className="thumbnail">
                <div className="larg-thumb sal-animate" data-sal="zoom-in" data-sal-duration="600" data-sal-delay="100">
                  <img loading="lazy"  className="dark-logo" src={chat_group} alt="" />
                </div>
                <ul className="list-unstyled small-thumb"><li class="shape shape-1 sal-animate" data-sal="slide-right" data-sal-duration="800" data-sal-delay="400">
                  <img loading="lazy"  className="paralax-image" src={laptop_poses}  alt="right image" />
                </li>
                  <li className="shape shape-2 sal-animate" data-sal="slide-left" data-sal-duration="800" data-sal-delay="300">
                    <img loading="lazy"  className="paralax-image" src={bill_pay}  alt="Left Image" />
                  </li>
                </ul>
              </div>
            </div>
          </Container>
         </div>
         </div>
    </StyledDIV>
  )
}

export default SectionFFf