import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import im01 from '../images/bubble-39.png';
import im02 from '../images/f1s.png';
import im03 from '../images/bubble-14.png';


const StyledDIV = styled.div`
    padding: 100px 0 0px;
.title {
    font-size: 64px;
    line-height: 1;
    font-weight: 700;
    text-transform: capitalize;
}
span.subtitle {
    font-size: 25px;
    margin-bottom: 40px;
    margin-top: 25px;
    width: 90%;
    color: #535361;
    display: flex;
}
.axil-btn {
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.04em;
    padding: 18px 55px;
    height: auto;
    text-align: center;
    transition: all 0.3s ease-in-out;
    position: absolute;
    z-index: 1;
}
.axil-btn {
    box-shadow: 0 44px 84px -24px #6c0fd8;
}
.axil-btn.btn-fill-primary {
    background-color: #6c0fd8;
    color: #fff;
    border: 1px solid #6c0fd8;
    overflow: hidden;
}
.axil-btn.btn-fill-primary:hover {
    background-color: #5500b7;
    border-color: #5500b7;
}
.axil-btn.btn-fill-primary::after {
    content: "";
    height: 300px;
    width: 300px;
    background-color: #5500b7;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0);
    transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    z-index: -1;
}
.axil-btn.btn-fill-primary:hover::after {
    transform: translateY(-50%) translateX(-50%) scale(1);
}
.banner-thumbnail {
    z-index: 5;
    position: relative;
}
.banner-thumbnail .large-thumb-2 {
    text-align: right;
    margin-top: -130px;
    margin-right: -50px;
    left: -100px;
    position: absolute;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
}
.banner-thumbnail .shape-group .shape {
    position: absolute;
}
.banner-thumbnail .shape-group .shape.shape-1 {
    top: -25px;
    right: 20px;
    left: auto;
}
.shape-group-21 .shape {
    position: absolute;
    z-index: -1;
}
li.shape.shape-1.sal-animate img {
    max-width: 527px;
    display: none;
}
.shape-group-21 .shape.shape-1 {
    top: 0;
    left: 0;
}
.shape-group-21 .shape.shape-2 {
    top: 200px;
    right: 200px;
    text-align: right;
}

.shape-group-21 .shape.shape-3 {
    right: 100px;
    top: 45%;
    height: 53px;
    width: 53px;
}
.shape-group-21 .shape.shape-4 {
    left: 220px;
    top: 45%;
    height: 20px;
    width: 20px ;
    animation: movingleftright1 infinite 25000ms;
}
.shape-group-21 .shape.shape-5 {
    left: 45%;
    bottom: 130px;
    height: 20px;
    width: 20px;
    animation: movingleftright3 infinite 20000ms;
}
@keyframes movingleftright1 {
    0% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
    }

    40% {
        -webkit-transform: translateX(50px) translateY(-200px);
        transform: translateX(50px) translateY(-200px);
    }

    75% {
        -webkit-transform: translateX(800px);
        transform: translateX(800px);
    }

    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
    }
}

@keyframes movingleftright2 {
    0% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
    }

    40% {
        -webkit-transform: translateX(-50px) translateY(200px);
        transform: translateX(-50px) translateY(200px);
    }

    75% {
        -webkit-transform: translateX(-500px);
        transform: translateX(-500px);
    }

    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
    }
}

@keyframes movingleftright3 {
    0% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
    }

    40% {
        -webkit-transform: translateX(100px) translateY(-200px);
        transform: translateX(100px) translateY(-200px);
    }

    75% {
        -webkit-transform: translateX(-500px);
        transform: translateX(-500px);
    }

    100% {
        -webkit-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
    }
}
@media only screen and (max-width: 1000px) {
    .title {
    font-size: 55px;
    }
    .banner-thumbnail {
    z-index: 5;
    position: relative;
    margin-top: 114px;
}
.large-thumb-2.sal-animate img {
    max-width: 100%;
}
.large-thumb.sal-animate img {
    max-width: 100%;
}

}
@media only screen and (max-width: 500px) {
    .title {
    font-size: 44px;
    }
    span.subtitle{
    font-size: 22px;
    width: 100%;
    }
     .banner-thumbnail .large-thumb-2 {
    text-align: right;
    margin-top: -29px;
    margin-right: -50px;
    left: -60px;
    position: absolute;
}

}


li.shape.shape-5.sal-animate ,li.shape.shape-3.sal-animate,li.shape.shape-4.sal-animate{
    filter: hue-rotate(45deg);
}

`;
export const SectionDs = () => {
  return<StyledDIV id="Home">
      <Container>
  <Row>
 
    <Col lg={6}>
    <Fade bottom>
    <h1 className="title">Building Digital Products and Brands</h1>
<span className="subtitle"> We  create awesome Website,Apps and Brands for your business.</span>
     <Link to='/Request/' className="axil-btn btn-fill-primary btn-large">Get Started</Link>
    </Fade>
    </Col>
    
    <Col lg={6}><div class="banner-thumbnail">
{
    /*
    <div className="large-thumb sal-animate" >
<Zoom>
<img src="https://axilthemes.com/themes/abstrak/wp-content/plugins/axil-elements/assets/media/poster-banner/window.png" alt=""/>
</Zoom>
</div>
    */
}


  <div className="large-thumb-2 sal-animate" >
  <Zoom>
<img src={im02} alt=""/>
</Zoom>
</div>

{
    /*
    <ul className="list-unstyled shape-group">

         <li className="shape shape-1 sal-animate" >
         <Zoom>    
<img src="https://axilthemes.com/themes/abstrak/wp-content/plugins/axil-elements/assets/media/poster-banner/chat-group.png" alt=""/>
</Zoom>
</li>
</ul> 
    */
}



</div></Col>
  </Row>
</Container>
<ul className="list-unstyled shape-group-21">
                    <li className="shape shape-1 sal-animate">
                    <Fade top>
                <img loading="lazy"  src={im01} alt=""/>
                </Fade>
            </li>
          
              
            <li className="shape shape-2 sal-animate">
                <Fade left>
                {/*<img src="https://axilthemes.com/themes/abstrak/wp-content/plugins/axil-elements/assets/media/poster-banner/bubble-38.png" alt=""/>*/}
                </Fade>
            </li>
                            <li className="shape shape-3 sal-animate">
                            <Fade left>
                <img loading="lazy"  src={im03} alt=""/>
                </Fade>
            </li>
                            <li className="shape shape-4 sal-animate" data-sal="slide-left">
                            <Fade left>
                            <img loading="lazy"  src={im03} alt=""/>
                </Fade>
            </li>
                            <li className="shape shape-5 sal-animate" data-sal="slide-left">
                            <Fade left>
                            <img loading="lazy"  src={im03} alt=""/>
                </Fade>
            </li>

            </ul>
  </StyledDIV>;
};
