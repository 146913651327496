import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import im1 from '../images/testimonial-2.webp';
import im2 from '../images/testimonial-3.webp';
import im3 from '../images/testimonial-1.png';
const StyledDIV = styled.div`
.SectionDq {
    padding: 130px 0;
}
.heading-left {
    text-align: left;
}
.section-heading .subtitle {
    color: #6c0fd8;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 15px;
}
.section-heading .title {
    font-size: 64px;
    margin-bottom: 40px;
}
.heading-left p {
    margin: 0 0 30px;
}
.section-heading p {
    font-size: 18px;
    width: 50%;
    font-weight: 500;
    color: #5d5361;
}

.testimonial-grid p {
    font-size: 24px;
    line-height: 1.2;
    color: #292930;
    letter-spacing: -0.025em;
    margin-bottom: 40px;
}
.testimonial-grid {
    padding: 50px 0 30px;
    border-top: 2px solid transparent;
    transition: all 0.3s ease-in-out;
}
.testimonial-grid .author-info {
    display: flex;
    align-items: center;
}
.testimonial-grid .author-info .thumb img {
    width: 50px;
    height: 50px;
}
.testimonial-grid .author-info .thumb img {
    border-radius: 50%;
}
.testimonial-grid .author-info .content {
    flex: 1;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
}
.testimonial-grid .author-info .content .name {
    font-weight: 500;
    color: #292930;
    letter-spacing: -0.04em;
    margin-bottom: 3px;
}
.testimonial-grid .author-info .content .designation {
    font-size: 14px;
    color: #999fae;
    letter-spacing: -0.025em;
}
.testimonial-grid:hover {
    border-top-color: #6c0fd8;
}

@media only screen and (max-width: 1000px) {
    .section-heading p{
        width: 100%;
    }

}
@media only screen and (max-width: 500px) {
    .section-heading .title{
        font-size: 44px;
    }
}


`;

const SectionDq = () => {
    return <StyledDIV>
        <div className='SectionDq'>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="section-heading heading-left">
                            <span className="subtitle">Testimonial</span>
                            <h2 className="title">The opinions of our clients</h2>

                        </div>
                    </Col>
                    <Col>
                    <Row>
                        <Col xl={4} lg={4} md={6} sm={12}>
                        <div className="testimonial-grid">  
                         <p className="subtitle">Awesome to work with!! Incredibly fast even with the time difference and I prolonged the process accidentally with login issues on my end. Would highly recommend!</p>
                         <div className="author-info">
                          <div className="thumb">
                              <img width="50" height="50" loading="lazy"  src={im1} class="attachment-full size-full" alt="" />
                             </div>
                            <div className="content">
                                <span className="name">Royalprincessle</span>
                                <span className="designation">United States</span>
                            </div>
                        </div>
                       </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12}>
                        <div className="testimonial-grid">  
                         <p className="subtitle">The communication was awesome. The delivery was brilliant. I would definitely recommend DevGate to anyone who wants their work done professionally and in record time. Thanks DevGate</p>
                         <div className="author-info">
                          <div className="thumb">
                              <img width="50" height="50" loading="lazy"  src={im2} class="attachment-full size-full" alt="" />
                             </div>
                            <div className="content">
                                <span className="name">leodesignmedtec</span>
                                <span className="designation">Canada</span>
                            </div>
                        </div>
                       </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12}>
                        <div className="testimonial-grid">  
                         <p className="subtitle">DevGate is great to work with. He has a very structured price and timeframe which as a grant writing research is so important! <br/> Would highly recommend!</p>
                         <div className="author-info">
                          <div className="thumb">
                              <img width="50" height="50" loading="lazy"  src={im3} class="attachment-full size-full" alt="" />
                             </div>
                            <div className="content">
                                <span className="name">Martha Maldonado</span>
                                <span className="designation">Canada</span>
                            </div>
                        </div>
                       </div>
                        </Col>
                    </Row>
                    </Col>
                </Row>

            </Container>
        </div>
    </StyledDIV>;
};

export default SectionDq;
