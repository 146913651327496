import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import chat_group from '../images/chat-group.png';
import laptop_poses from '../images/laptop-poses.png';
import bill_pay from '../images/bill-pay.png';
import bubble12 from '../images/bubble-12.png';
import bubble16 from '../images/bubble-16.png';
import bubble13 from '../images/bubble-13.png';
import bubble14 from '../images/bubble-14.png';
import bubble15 from '../images/bubble-15.png';

const StyledDIV = styled.div`
.call-to-action {
    border-bottom: 1px solid #e7e7e7;
}
.call-to-action .section-heading {
    margin-bottom: 65px;
}
.section-heading {
    text-align: center;
    margin-bottom: 60px;
}
.call-to-action .section-heading span {
    color: var(--color-accent1);
}
.section-heading .subtitle {
    color: #fff !important;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 15px;
}
.call-to-action .section-heading .title {
    width: 60%;
    margin: 10px auto 55px;
}
.heading-light .title {
     color: #fff;
    font-size: 64px;
}
.axil-btn.btn-large {
    padding: 23px 55px;
}
.axil-btn.btn-fill-white {
    background-color: #fff;
    box-shadow: 0px 48px 48px 0px rgb(0 0 0 / 12%);
}

.axil-btn {
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -.04em;
    padding: 18px 55px;
    height: auto;
    text-align: center;
    transition: var(--transition);
    position: relative;
    z-index: 1;
    color: #292931;
}
.call-to-action .thumbnail {
    position: relative;
    text-align: center;

}
.call-to-action .thumbnail .larg-thumb {
    display: inline-block;
    margin-right: -180px;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
}
.call-to-action .thumbnail .small-thumb li.shape-1 {
    bottom: 0;
    left: 155px;
}
.call-to-action .thumbnail .small-thumb li {
    position: absolute;
}
.call-to-action .thumbnail .small-thumb li.shape-2 {
    bottom: 0;
    right: 80px;
}
.call-to-action-area:before {
    content: "";
    height: 75%;

    width: 100%;
    background-color: #27272e;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
}
.call-to-action-area {
  position: relative;
    padding: 80px 0;
    overflow: hidden;
}
.shape-group-9 .shape {
    position: absolute;
    z-index: -1;
}
.list-unstyled li {
    margin-top: 0;
    margin-bottom: 0;
}
.shape-group-9 .shape.shape-1 {
    top: 0;
    left: 32%;
}
.shape-group-9 .shape.shape-2 {
    top: 180px;
    left: 18%;
    opacity: .5;
    animation: movingleftright1 infinite 20s;
}
.shape-group-9 .shape.shape-3 {
    top: 31%;
    left: 20%;
    animation: movingleftright2 infinite 25s;
}
.shape-group-9 .shape.shape-4 {
    top: 44%;
    left: 28%;
    animation: movingleftright1 infinite 25s;
}
.shape-group-9 .shape.shape-5 {
    top: 50%;
    left: 17%;
    animation: movingleftright2 infinite 30s;
}
.shape-group-9 .shape.shape-6 {
    top: 26%;
    right: 21%;
    animation: movingleftright3 infinite 30s;
}
.shape-group-9 .shape.shape-7 {
    top: 30%;
    right: 15%;
    animation: movingleftright1 infinite 30s;
}

li.shape.shape-4,li.shape.shape-6 {
    display: none;
}

@media only screen and (max-width: 500px) {
    .heading-light .title{
        font-size: 35px;
        width: 100% !important;
    }
    .call-to-action .thumbnail .larg-thumb {
    margin-right: 0;
}
}
@media only screen and (max-width: 991px){
  .call-to-action .thumbnail .larg-thumb {
    margin-right: -130px;
}
.call-to-action .thumbnail .larg-thumb img {
    width: 70%;
}
.call-to-action .thumbnail .small-thumb li.shape-1 {
    left: -50px;
}
.call-to-action .thumbnail .small-thumb li img {
    width: 70%;
}
.call-to-action .thumbnail .small-thumb li.shape-2 {
    right: -95px;
}
}
@media only screen and (max-width: 767px){
  .call-to-action .thumbnail .larg-thumb {
    margin-right: -100px;
}
.call-to-action .thumbnail .larg-thumb img {
    width: 50%;
}
.call-to-action .thumbnail .small-thumb li.shape-1 {
    left: -70px;
}
.call-to-action .thumbnail .small-thumb li img {
    width: 35%;
}
.call-to-action .thumbnail .small-thumb li.shape-2 {
    right: -110px;
}
}
@media only screen and (max-width: 575px){
  .call-to-action .thumbnail .larg-thumb {
    margin-right: -50px;
}
.call-to-action .thumbnail .larg-thumb img {
    width: 50%;
}
.call-to-action .thumbnail .small-thumb li.shape-1 {
    left: -125px;
}
.call-to-action .thumbnail .small-thumb li.shape-2 {
    right: -130px;
}
}


`;

const SectionFF = () => {
  return (
    <StyledDIV>

      <div className='SectionFF'>

        <div className='class="section call-to-action-area has-image"'>
          <Container>
            <div className="call-to-action">
              <div className="section-heading heading-light">
                <span className="subtitle">Need a successful project?</span>
                <h2 className="title">Lets Work Together</h2>
                <Link to='/Request/'  className="axil-btn btn-large btn-fill-white">Estimate Project</Link>
              </div><div className="thumbnail">


                <div className="larg-thumb sal-animate" data-sal="zoom-in" data-sal-duration="600" data-sal-delay="100">
                  <img loading="lazy"  className="dark-logo" src={chat_group} alt="" />
                </div>
                <ul className="list-unstyled small-thumb"><li class="shape shape-1 sal-animate" data-sal="slide-right" data-sal-duration="800" data-sal-delay="400">
                  <img loading="lazy"  className="paralax-image" src={laptop_poses} alt="right image" />
                </li>
                  <li className="shape shape-2 sal-animate" data-sal="slide-left" data-sal-duration="800" data-sal-delay="300">
                    <img loading="lazy"  className="paralax-image" src={bill_pay}  alt="Left Image" />
                  </li>
                </ul>
              </div>
            </div>
          </Container>
          <ul className="list-unstyled shape-group-9">
            <li className="shape shape-1">
              <img loading="lazy"  src={bubble12} alt="bubble-12"/>
              </li>
              <li className="shape shape-2">
                <img loading="lazy"  src={bubble16}  alt="bubble-16"/>
                </li><li className="shape shape-3">
                  <img loading="lazy"  src={bubble13} alt="bubble-13"/>
                  </li><li className="shape shape-4"><img loading="lazy"   src={bubble14}  alt="bubble-14"/>
                  </li><li className="shape shape-5"><img loading="lazy"  src={bubble16}  alt="bubble-16"/>
                  </li><li className="shape shape-6"><img loading="lazy"  src={bubble15}  alt="bubble-15"/></li>
                  <li className="shape shape-7"><img loading="lazy"  src={bubble16}  alt="bubble-16"/></li></ul>
                  </div>
                </div>

    </StyledDIV>
  )
}

export default SectionFF