import React,{useState} from 'react';
import styled  from 'styled-components';
import { Tab,Tabs,Container,Row,Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import im6 from '../images/11.jpg';
import im7 from '../images/12.jpg';
import im8 from '../images/13.jpg';
import im9 from '../images/14.jpg';
import im11 from '../images/16.jpg';
import im12 from '../images/17.jpg';
import im13 from '../images/18.jpg';

import circle2 from '../images/circle-2.png'
import bubble2 from '../images/bubble-2.png'
import bubble1 from '../images/bubble-1.png'      


const StyledDIV=styled.div`
padding: 140px 0 100px;
.section-heading.heading-dark-left {
    text-align: left;
}
.section-heading .subtitle {
    color: #6c0fd8;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 15px;
}
.section-heading.heading-dark-left .title {
    font-size: 64px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 60px;
}
.nav-tabs,.nav-tabs .nav-link:hover,.nav-tabs .nav-link.active {
    border: none !important;
    outline: none;
}
li.nav-item:focus-visible{
    outline: none; 
}

.nav-link {
    color: #212529;
}
ul.mb-3.nav.nav-tabs li {
    position: relative;
    margin: 0 70px 0 0;
}
.nav-link.active:after {
    content: "";
    height: 2px;
    width: 70%;
    background-color: #6c0fd8;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.Section_dfs .thumbnail img {
    border-radius: 30px 30px 0 0;
    position: relative;
    width: 100%;
    transition: all 0.3s ease-in-out;
    transform: scale(1);
}
.Section_dfs .thumbnail{
    overflow: hidden;
    border-radius: 30px 30px 0 0;
}

.Section_dfs .content {
    background-color: #fff;
    border-radius: 0 0 30px 30px;
    padding: 40px 50px;
    color:#292930;
}
.Section_dfs {
    box-shadow: 0 55px 86px -35px #ecf2f6;
    border-radius: 30px;
    margin-bottom: 40px;
}
.Section_dfs:hover .thumbnail img {
    transform: scale(1.2);
}
.tab-content>.tab-pane {
    padding: 30px 0;
}
.cat_dev a {
    color: #707070;
    margin: 0px 20px 0px 0;
}
.cat_dev {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
li.nav-item button {
    font-weight: 700;
    color: #212529 !important;
}
.Section_Db{
    position: relative;
    z-index: 1;
}
.shape-group-7 .shape.shape-1 {
    top: -70px;
    right: 200px;
}
.shape-group-7 .shape {
    position: absolute;
    z-index: -1;
}
.shape-group-7 .shape.shape-2 {
    top: 45px;
    left: 52%;
}
.shape-group-7 .shape.shape-3 {
    top: 38%;
    left: 180px;
}

@media only screen and (max-width: 1388px) {
.contact-form-box {
    margin: 0 10px;
}

}
@media only screen and (max-width: 500px) {
.section-heading.heading-dark-left .title {
font-size: 44px;
}
ul.mb-3.nav.nav-tabs li {
    position: relative;
    margin: 0 0px 0 0;
}
.thumbnail img {
    max-height: 280px;
}
}

li.shape.shape-1 {
    filter: hue-rotate(19deg);
}
li.shape.shape-2 {
    filter: saturate(1.4);
}
`;

const SectionDb = () => {
    const [key, setKey] = useState('Websites');
  return <StyledDIV id="Projects">
      <div className='Section_Db'>
          <Container>
        <div class="section-heading heading-dark-left">
        <span class="subtitle">Our Project</span> 
              <h2 class="title">Some of our work.</h2>    

          </div>
         
          <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="Websites" title="Websites">
      <Row>
      <Col xl={6} lg={6} md={6} sm={12} >
      <Fade bottom >
          <div className='Section_dfs'>
                <div class="thumbnail">
                    <img width="610" height="460" loading="lazy"  src={im6}/>
                </div>
                <div class="content">
                    <h4 class="title">Portfolio Website</h4>   
                    <div className='cat_dev'>
                    <a href="#">Web Application</a> 
                    </div>           

                </div>
            </div>
            </Fade>
          </Col>

          <Col xl={6} lg={6} md={6} sm={12} >
          <Fade bottom >
          <div className='Section_dfs'>
                <div class="thumbnail">
                    <img width="610" height="460"  loading="lazy"  src={im7}/>
                </div>
                <div class="content">
                    <h4 class="title">E-commerce Website</h4>   
                    <div className='cat_dev'>
                    <a href="#">Web Application</a> 
                    <a href="#">E-commerce</a> 
                    </div>           

                </div>
            </div>
            </Fade>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} >
      <Fade bottom >
          <div className='Section_dfs'>
                <div class="thumbnail">
                    <img width="610" height="460" loading="lazy"  src={im8}/>
                </div>
                <div class="content">
                    <h4 class="title">E-commerce Website</h4>   
                    <div className='cat_dev'>
                    <a href="#">Web Application</a> 
                    <a href="#">E-commerce</a> 
                    </div>           

                </div>
            </div>
            </Fade>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} >
          <Fade bottom >
          <div className='Section_dfs'>
                <div class="thumbnail">
                    <img width="610" height="460" loading="lazy"  src={im9}/>
                </div>
                <div class="content">
                    <h4 class="title">E-commerce Website</h4>    
                    <div className='cat_dev'>
                    <a href="#">Web Application</a> 
                    <a href="#">E-commerce</a> 
                    </div>           

                </div>
            </div>
            </Fade>
          </Col>
       </Row> 
      </Tab>
      <Tab eventKey="Apps" title="Apps">
      <Row>

          <Col xl={6} lg={6} md={6} sm={12} >
          <Fade bottom >
          <div className='Section_dfs'>
                <div class="thumbnail">
                    <img width="610" height="460" loading="lazy"  src={im11}/>
                </div>
                <div class="content">
                    <h4 class="title">AllResto</h4>    
                    <div className='cat_dev'>
                    <a href="#">Mobile Application</a> 
                    <a href="#">E-commerce</a> 
                    </div>           

                </div>
            </div>
            </Fade>
          </Col>

       </Row> 
      </Tab>
      <Tab eventKey="Branding" title="Branding">
      <Row>
          <Col xl={6} lg={6} md={6} sm={12} >
          <Fade bottom >
          <div className='Section_dfs'>
                <div class="thumbnail">
                    <img width="610" height="460" loading="lazy"  src={im12}/>
                </div>
                <div class="content">
                    <h4 class="title">Studio Skina</h4>   
                    <div className='cat_dev'>
                    <a href="#">Branding</a> 
                    <a href="#">Logo</a> 
                    </div>           

                </div>
            </div>
            </Fade>
          </Col>

          <Col xl={6} lg={6} md={6} sm={12} >
          <Fade bottom >
          <div className='Section_dfs'>
                <div className="thumbnail">
                <img width="610" height="460" loading="lazy"  src={im13}/>
                </div>
                <div className="content">
                    <h4 className="title">Nature Raura</h4>   
                    <div className='cat_dev'>
                    <a href="#">Branding</a> 
                    <a href="#">Logo</a> 
                    </div>           

                </div>
            </div>
            </Fade>
          </Col>




      </Row>

 
      </Tab>
      
    </Tabs>
          </Container>
          <ul class="shape-group-7 list-unstyled">
                         
                         <li class="shape shape-1"><img src={circle2} alt=""/></li>
                         <li class="shape shape-2"><img src={bubble2} alt=""/></li>  
                         <li class="shape shape-3"><img src={bubble1} alt=""/></li>
                      
                 </ul>
      </div>

  </StyledDIV>;
};

export default SectionDb;
