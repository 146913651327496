import React from 'react';
import { NavBar } from '../com/NavBar';
import styled  from 'styled-components';
import { SectionDs } from '../com/SectionDs';
import { SectionDm } from '../com/SectionDm';
import SectionDj from '../com/SectionDj';
import SectionDi from '../com/SectionDi';
import SectionDb from '../com/SectionDb';
import SectionDo from '../com/SectionDo';
import SectionDq from '../com/SectionDq';
import Footer from '../com/Footer';


const StyledDIV  = styled.div`
.df{
    position: relative;
    z-index: 2;
    min-height: 100vh;
    overflow: hidden;
}
@media only screen and (max-width: 500px) {
.df {
    position: relative;
    z-index: 2;
    min-height: 940px;
    overflow: hidden;
}
}

`;
export const Home = () => {
  return <StyledDIV>
    <div className='df'>
    <NavBar/>
      <SectionDs/>
    </div>
    <SectionDm/>
    <SectionDj/>
    <SectionDi/>
    <SectionDb/>
    <SectionDo/>
    <SectionDq/>
    <Footer/>
    {/* 
   
 
  
 
  
    */}  
    
  </StyledDIV>;
};
