import React from 'react';
import { Container, Row ,Col } from 'react-bootstrap';
import styled from 'styled-components';
import SectionFF from './SectionFF';
import { BsTwitter,BsFacebook,BsLinkedin,BsInstagram,BsTelephoneFill,BsEnvelopeFill,BsPinMapFill } from "react-icons/bs";
import SectionFFf from './SectionFFf';

const StyledDIV = styled.div`
.footer-top {
    padding-bottom: 70px;
    position: relative;
    overflow: hidden;

}
ul.list-unstyled {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -100px;
}
ul.list-unstyled  li {
    margin: 0 100px;
}
ul.list-unstyled li a svg {
    width: 30px;
    height: 30px;
    color: #b5bac5;
    cursor: pointer;
    transition: all .3s ease-in-out;
}
ul.list-unstyled li a svg:hover {
    color: #6c0fd8;
}
.input-group {
    background-color: #fff;
    border-radius: 20px;
    height: 72px;
    padding: 10px 10px 10px 55px;
    box-shadow: 0 24px 48px -15px rgb(153 161 170 / 25%);
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
.input-group .form-control {
    padding: 5px 15px;
    height: auto;
    border: none;
    background-color: transparent;
}
.input-group .subscribe-btn {
    border: none;
    background-color: #6c0fd8;
    color: #fff;
    border-radius: 16px !important;
    margin-left: 0 !important;
    padding: 10px 28px;
    transition: all .3s ease-in-out;
    width: 200px;
}

.input-group>.form-control, .input-group>.form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;

}
input, .form-control {
    font-size: 15px;
    

}
input, .form-control {
    border: var(--border-lighter);
    border-radius: 16px;
    background-color: transparent;
    color: var(--color-text-dark);
    padding: 15px 20px;
    width: 100%;
    height: 100% !important;
}
.input-group:before {
    content: url('https://sp-ao.shortpixel.ai/client/to_auto,q_glossy,ret_img/https://axilthemes.com/themes/abstrak/wp-content/themes/abstrak/assets/css/../../assets/media/icon/message.png');
    position: absolute;
    left: 30px;
    top: 24px;
    z-index: 1;
}
.form-control:focus {
    box-shadow:none;
}
.footer-newsletter .title {
    margin-bottom: 20px;
    letter-spacing: -.045em;
    font-size: 64px;
}
.footer-newsletter p{
    font-size:16px;
margin: 0 0 40px;
color:#525260;

}
.section_newsletter{
    border-right-color: #c7c7d5 !important;
    border-right-style: solid;
    border-width: 2px;
    padding-right: 80px;
    margin-right: 40px;
}


.footer-menu-link .title,.section_icon_contect h6 {
    font-weight: 700;
    font-size: 24px;
}
ul#menu-support {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
ul#menu-support li a {
    font-weight: 400;
    font-size: 18px;  
    color: #525260;
}
 ul#menu-support li a:hover {
    color: #6c0fd8;
}
.icons_contact {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-around;
}
.ic {
    padding: 10px;
    margin: 10px;
    background-color: #fff;
    box-shadow: 16px -12px 48px -15px rgb(0 0 0 / 51%);
    border-radius: 5px;
}
.ic svg {
    width: 25px;
    height: 25px;
    color: #525260;
}
.icons_contact h3 {
    font-size: 18px;
    font-weight: 400;
    color: #525260;
    width: 100%;
}
/*
.icons_contact:hover .ic {
    background: rgb(84,101,255);
    background: linear-gradient(234deg,rgb(89 86 233) 0%,rgb(255 255 255) 100%);
}
.icons_contact:hover .ic svg{
    color: #fff;
}*/
.icons_contact {
    cursor: pointer;
}
.footer-copyright {
    text-align: center;
    padding: 20px 0 20px 0;
    border-top-style: solid;
    border-width: 1px;
    margin-top: 70px;
    border-color: #c7c7d5;
}
.footer-copyright .copyright-text,.footer-copyright .copyright-text a {
    color: #757589;
}
span.copyright-text a:hover {
    color: #6c0fd8 !important;
}

@media only screen and (max-width: 991px){

}
@media only screen and (max-width: 767px){
.footer-newsletter .title {
    font-size: 34px;
}
}
@media only screen and (max-width: 575px){

ul.list-unstyled li {
    margin: 0 20px;
}
.section_newsletter{
    border-width: 0;
    padding-right: 0;
    margin-right: 0;
    border: none;
}
.input-group .subscribe-btn{
    width: 123px;
}
.footer-menu-link {
    display: none;
}
.section_icon_contect {
    margin-top: 50px;
}
}

`;

const Footer = (props) => {
  return (
    <>
    {props.name!="page"?<SectionFF/>:<></>}
   
    <StyledDIV id="contact">
        <footer>
            <Container>
            <div className="footer-top">
            <ul className="list-unstyled">
                <li>
                    <a class="facebook-f social-icon" href="https://twitter.com/"  target="_blank">
                    <BsTwitter/>
                    </a>
                </li>
                <li>
                    <a class="twitter social-icon" href="https://www.facebook.com/devgatealgeria"  target="_blank">
                    <BsFacebook/>
                    </a>
                </li>
                <li><a class="pinterest-p social-icon" href="https://linkedin.com/"  target="_blank">
                    <BsLinkedin/>
                    </a>
                </li>
                <li><a class="linkedin-in social-icon" href="https://www.instagram.com/devgatealgeria"  target="_blank">
                    <BsInstagram/>
                    </a>
                </li>
            </ul>
            </div>
            <div className="footer-main">
                <Row>
            <Col lg={4} xl={6}>
            <div className='section_newsletter'>
            <div className="footer-newsletter">
                <h2 className="title">Get in touch!</h2>
                 <p>By subscribing to us, you will receive our new offers exclusively via your personal email</p>
                <div className="input-group"> 
                <input className="form-control" type="email" name="EMAIL" placeholder="Your email address" required=""/> 
                <input className="subscribe-btn" type="submit" value="Subscribe"/>
                </div>
            </div>
            </div>
           
                    </Col>
                    <Col lg={8} xl={6}>
                        <Row>
                            <Col xl={6} lg={3} md={3}>
                            <div className="footer-menu-link">
                                <h6 className="title">Support</h6>
                                <nav className="menu-support-container" aria-label="Support">
                                    <ul id="menu-support" className="list-unstyled">
                                        <li id="menu-item-1381" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1381">
                                            <a href="#" className="nav-link">Contact</a></li>
                                        <li id="menu-item-1382" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-1382">
                                            <a href="#" className="nav-link">Privacy Policy</a></li>
                                        <li id="menu-item-1383" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1383">
                                            <a href="#" className="nav-link">Terms of Use</a></li>
                                    </ul>
                                </nav>
                            </div>
                            </Col>
                            <Col xl={6} lg={9} md={9}>
                                <div className='section_icon_contect'>
                                    <h6 className="title">Contact us</h6>
                                    <div className='icons_contact'><div className='ic'><BsPinMapFill/></div> <h3>Alger,Algeria </h3></div>
                                    <div className='icons_contact'><div className='ic'><BsTelephoneFill/></div> <h3>(+213) 770 17 17 95  <br/> (+213) 770 24 24 45 <br/>   (+213) 540 71 23 93 </h3></div>
                                    <div className='icons_contact'><div className='ic'><BsEnvelopeFill/></div> <h3>contact@devgat.net</h3></div>
                                </div>
                            </Col>
                        </Row>
                        
                    </Col>
                </Row>
    

            </div>
            <div className="footer-bottom">
                <Row>
                    <Col>
                    <div class="footer-copyright"> <span class="copyright-text">© 2022. All rights reserved by <a href="#" target="_blank" rel="noopener">DevGate.</a></span></div>
                    </Col>
                </Row>

            </div>
            </Container>
        </footer>

    </StyledDIV>


    </>
  )
}

export default Footer