import React, { useEffect } from 'react';
import { NavBar } from '../com/NavBar';
import styled  from 'styled-components';
import { SectionDs } from '../com/SectionDs';
import Footer from '../com/Footer';
import SectionPages from '../com/SectionPages';
import SectionFFf from '../com/SectionFFf';


const StyledDIV  = styled.div`
.df{
    position: relative;
    z-index: 2;
    overflow: hidden;
    background-color: #f6f8fa;
}
@media only screen and (max-width: 500px) {
.df {
    position: relative;
    z-index: 2;
    overflow: hidden;
}
}

`;


export const Domande = () => {
  useEffect(()=>{ window.scrollTo(0, 0)},[]);
  return <StyledDIV>
        <div className='df'>
         <NavBar/>
         <SectionPages/>
        </div>
        <SectionFFf/>
    <Footer name='page'/>
  </StyledDIV>;
};
