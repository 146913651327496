import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`



*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

  body,html {
    margin: 0;
    padding: 0;
    font-family: 'DM Sans', sans-serif;
    height: -webkit-fill-available;
  }
 a,h1,span,h2,h3,h4 {
   /* font-family: 'Nunito', sans-serif;*/
   font-family: 'DM Sans', sans-serif;
 }
 a{
  text-decoration: none;
  cursor: pointer;
 }

 /* Offcanvas_style_global */

 #Offcanvas_style_global .offcanvas-header {
  flex-direction: row-reverse;
 }
 #Offcanvas_style_global{
    width: 50%;
 }
 #Offcanvas_style_global .offcanvas-header {
    padding: 50px;
}
#Offcanvas_style_global .offcanvas-body {
    padding: 50px 50px;
}
#Offcanvas_style_global .section_menu_contect .nav-link {
    padding: 20px 0;
    font-size: 30px;
    font-weight: 800;
    color: #27272e;
}
#Offcanvas_style_global .section_menu_contect .nav-link:hover {
    color: #6c0fd8 !important;
}
#Offcanvas_style_global .Offcanvas_contact {
    padding-top: 25px;
}
#Offcanvas_style_global .Offcanvas_contact h5 {
    font-weight: 700;
    color: #27272e;
}
#Offcanvas_style_global .Offcanvas_contact p {
    color: #525260;
}
#Offcanvas_style_global .contact_2 {
    display: flex;
    flex-direction: column;
}
#Offcanvas_style_global .contact_3 {
    padding-top: 25px;
}
#Offcanvas_style_global .contact_2 a {
  color: #525260;
    margin-bottom: 10px;

}
#Offcanvas_style_global .contact_2 svg {
    margin-right: 15px;
}
#Offcanvas_style_global .contact_3 a {
    padding: 15px;
    background-color: antiquewhite;
    margin: 5px;
    border-radius: 38px;
    color: #fff;
}
#Offcanvas_style_global .contact_3 h5 {
    margin-bottom: 30px;
}
#Offcanvas_style_global .contact_3 a svg {
    width: 20px;
}
#Offcanvas_style_global a.icon_round.fb {
    background-color: #3b5998;
}
#Offcanvas_style_global a.icon_round.in {
    background-color: #c32aa3;
}
#Offcanvas_style_global a.icon_round.tw {
    background-color: #1da1f2;
}
#Offcanvas_style_global a.icon_round.lk {
    background-color: #0a66c2;
}
button.btn-wrap.btn.btn-primary {
    box-shadow: none !important;
}
.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    color: #6c0fd8;
}

.Style_mobile {
    /* text-align: center; */
    background-color: #27272e;
}
.Style_mobile  .navbar-nav {
    text-align: start;
    padding: 10px 20px;

}

.ddf_mobile{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 25px;
}
.Style_mobile {
    max-width: 100%;
}

.Style_mobile .navbar-nav a {
    color: #fff;
    font-size: 22px;
    font-weight: 400;
}
.Style_mobile  .btn-close {
    filter: invert(1);
}
.Style_mobile .Offcanvas_contact {
    color: #fff;
    text-align: center;
}
.Style_mobile img.logo_mobil {
    max-width: 143px;
    margin-right: 33%;
    margin-top: 0;
}
.Style_mobile .offcanvas-header{
    flex-direction: row-reverse;
}
.Style_mobile .offcanvas-header {
    margin-top: 4px;
}
.Style_mobile .Offcanvas_contact {
    margin-top: 26px;
     background-image: linear-gradient(to right, #08080869 , #27272e00);
    border-radius: 12px;
    color: #fff !important;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    text-align: start;
    padding-left: 25px;
}
.Style_mobile .contact_3 {
    display: flex;
    width: -webkit-fill-available;
    justify-content: space-evenly;
    max-width: 200px;
    margin: auto;
    padding: 20px;
}
.Style_mobile .contact_2 a ,.Style_mobile .contact_1 p {
    color: #ffffffa3;
}
.Style_mobile .contact_3 svg {
    color: #fff;
}
.Style_mobile .contact_1 h5,.Style_mobile .contact_2 h5  {
 
    font-weight: 600;
}
.navbar-light .navbar-toggler{

    border: none;
}
.navbar-toggler:focus{
    box-shadow: none;
}

`;
 
export default GlobalStyle;