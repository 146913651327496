import React from 'react';
import styled from 'styled-components';
import {Container,Row,Col} from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import about2 from '../images/about-2.png';


const StyledDIV = styled.div`
background-color: #27272e;
.section_do {
    padding: 125px 0;
    position: relative;
    z-index: 1;
}
.section-heading .subtitle {
    color: #ffffff;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 15px;
}
.heading-light .title {
    color: #fff;
    font-size: 64px;
    margin-top: 20px;
    padding-bottom: 30px;
}
.section-heading.heading-light.heading-light-left {
    max-width: 750px;
}
.heading-light p {
    color:#99a1aa;
    font-size: 18px;
}
.about-quality {
    margin-bottom: 60px;
    cursor: pointer;
}
.about-quality .sl-number {
    text-align: center;
    height: 80px;
    width: 80px;
    line-height: 80px;
    border-radius: 50%;
    background: linear-gradient(90deg, #565677 0%, rgba(39, 39, 46, 0) 70.31%);
    color: #fff;
    font-size:48px;
    margin-bottom: 40px;
    transition: all 0.3s ease-in-out;
}
.about-quality .title {
    color: #fff;
    font-weight: 500;
    font-size:24px;

}
.about-quality p {
    opacity: 0.5;
    color: #fff;
    transition: all 0.3s ease-in-out;
}
.about-quality:hover .sl-number{
    background-color: #6c0fd8;
}
.about-quality:hover p {
    opacity: 1;
}
.shape-group-9 .shape {
    position: absolute;
    z-index: -1;
}
.shape-group-9  li.shape.shape-1 {
    bottom: -208px;
    left: -87px;
}
.shape-group-9 li.shape.shape-2 {
    top: 120px;
    right: 196px;
}
.shape-group-9 li.shape.shape-2 img {
    max-width: 489px;
    transform: scaleX(-1);
}

@media only screen and (max-width: 1000px) {
.shape-group-9 li.shape.shape-2 {
    top: 0;
    right: 0;
}
.shape-group-9 li.shape.shape-2 img {
    max-width: 351px;
}

}
@media only screen and (max-width: 500px) {
 .heading-light .title {
font-size: 44px;
}
li.shape.shape-2 {
    display: none;
}

}

`;
const SectionDo = () => {
  return <StyledDIV >
            <div className='section_do'>
             <Container>
            <div className="section-heading heading-light heading-light-left">            
                    <span className="subtitle">Our Valus</span>
                    <h2 className="title">Why should you work with us?</h2> 
                    <p>The features we have from other competitors that make you work:</p>
            </div>

                <Row>
                    <Col xl={4} lg={4} md={6} sm={12}>
                    <Fade bottom >
                    <div className="about-quality">
                        <h3 className="sl-number">1</h3>
                        <h5 className="title"> You get an active competitive advantage installed in your business.</h5>
                        <p className="subtitle">With us, you get original solutions that are specifically designed for your work in high quality and mastery.</p>
                    </div>
                    </Fade>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12}>
                    <Fade bottom >
                    <div className="about-quality">
                        <h3 className="sl-number">2</h3>
                        <h5 className="title">You get original, solutions especially created for you.</h5>
                        <p className="subtitle">With us, you get unique features in your work that distinguish you from other competitors, making your work more discriminatory.</p>
                    </div>
                    </Fade>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                    <Fade bottom >
                    <div className="about-quality">
                        <h3 className="sl-number">3</h3>
                        <h5 className="title">You will deal with an integrated team with good experience.</h5>
                        <p className="subtitle">We assure you that you will be dealing with a well-experienced team working to master and complete your work on time by you.</p>
                    </div>
                    </Fade>
                    </Col>
                </Row>
        </Container>
        <ul className="shape-group-9 list-unstyled"> 
            {/*<li class="shape shape-1"><img src="https://axilthemes.com/themes/abstrak/wp-content/uploads/2021/10/circle-1.png" alt=""/></li>*/}
           
             <li className="shape shape-2"> <Fade right ><img loading="lazy"  src={about2}  alt=""/></Fade>  </li>       
       </ul>
      </div>
  </StyledDIV>;
};

export default SectionDo;
