import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Navbar, Nav, Button, Offcanvas ,Row , Col} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import logo_m from '../images/logo mobile.png';
import { FaPhone,FaFax,FaEnvelope,FaFacebookF,FaInstagram,FaTwitter,FaLinkedinIn } from "react-icons/fa";

const StyledDIV = styled.div`
    padding: 50px 0;
a.nav-link {
    font-size: 16px;
    font-weight: 600;
    color: #525260;
    margin: 0 30px;
    position: relative;
}
.btn-wrap {
    height: 60px;
    width: 60px;
    background-color: #ECF2F6;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
}
 .btn-wrap span {
    height: 2px;
    width: 20px;
    background-color: #27272E;
    margin-bottom: 4px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
}
.btn-wrap span:last-child {
    margin-bottom: 0;
    width: 10px;
    margin-right: -10px;
}
 .btn-wrap:hover span:first-child {
    width: 10px;
    margin-right: -10px;
}
 .btn-wrap:hover span:last-child {
    width: 20px;
    margin-right: 0;
}

a.nav-link::before {
    content: "";
    height: 2px;
    width: 0;
    background-color: #6c0fd8;
    position: absolute;
    bottom: 5px;
    left: 0;
    opacity: 0;
    transition: 0.5s;
}
a.nav-link:hover::before {
    opacity: 1;
    width: 100%;
}
a.nav-link:hover{
    color: #6c0fd8 !important;
}
a.navbar-brand img {
    width: 170px;
}
.fixed-top{
    background-color: white;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}
.navbar.navbar-expand.navbar-light {
      transition: all 0.1s ease-in-out;
}
#NaVsmall{
    display: none;
}
/*mobile*/
@media only screen and (max-width: 1000px) {
#NaVsmall{
    display: block;
}
#NaVlarg{
    display: none;
}


}

`;
export const NavBar = () => {
    const [show, setShow] = useState(false);
    const [Show_mobil,setShow_mobil] = useState(false);



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleShow_mobil = () => setShow_mobil(true);
    const handlehide_mobil =  () => setTimeout(() => {setShow_mobil(false);}, 100);  
    


    const [activeClass, setactiveClass] = useState("");
    useEffect(() => {
        window.addEventListener('scroll', () => {
            let activeClassd = '';
            if (window.scrollY > 300) {
                activeClassd = 'fixed-top';
            }
            setactiveClass(activeClassd);
        });
    });

    return <StyledDIV>
        <Navbar className={activeClass} id="NaVlarg">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img src={logo} alt='DevGat' />
                </Navbar.Brand>
                <Nav className="">
                <Nav.Link  href="#Home">Home</Nav.Link>
                    <Nav.Link href="#Services">Services</Nav.Link>
                    <Nav.Link href="#Featured">About us</Nav.Link>
                    <Nav.Link href="#About">Pricing</Nav.Link>
                    <Nav.Link href="#Projects">Projects</Nav.Link>
                    <Nav.Link href="#contact">Contact us</Nav.Link>
                </Nav>
                <div className='d-flex'>
                    <Button onClick={handleShow} className="btn-wrap">
                        <span></span>
                        <span></span>
                        <span></span>
                    </Button>
                </div>
            </Container>
        </Navbar>
        <Navbar expand={false}  className={activeClass}  id="NaVsmall">
            <Container fluid>
                <Navbar.Brand href="#Home"> <img src={logo} loading="lazy"  alt='DevGat' /></Navbar.Brand>
                <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow_mobil} />
                <Navbar.Offcanvas
                    scroll='true'
                    id="offcanvasNavbar"
                    className = "Style_mobile"
                    aria-labelledby="offcanvasNavbarLabel"
                    placement="end"
                    keyboard = 'true'
                    show={Show_mobil}
                >
                    <div className='ddf_mobile'>
                    <img src={logo_m} className='logo_mobil' alt='DevGat' />
                    <Offcanvas.Header onClick={handlehide_mobil}  closeButton >
                    </Offcanvas.Header>
                    </div>
                    <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link  onClick={handlehide_mobil} href="#Home">Home</Nav.Link>
                    <Nav.Link onClick={handlehide_mobil} href="#Services">Services</Nav.Link>
                    <Nav.Link onClick={handlehide_mobil} href="#Featured">About us</Nav.Link>
                    <Nav.Link onClick={handlehide_mobil} href="#About">Pricing</Nav.Link>
                    <Nav.Link onClick={handlehide_mobil} href="#Projects">Projects</Nav.Link>
                    <Nav.Link onClick={handlehide_mobil} href="#contact">Contact us</Nav.Link>
                        </Nav>
                        <div className='Offcanvas_contact'>
                      
                        <div className='contact_1'>
                            <h5>Address</h5>
                            <p>Alger,Algeria</p>
                        </div>
                        <div className='contact_2'>
                        <h5>Phone</h5>
                          <a class="tel" href="tel:+213770171795">(+213) 770 17 17 95 </a><br />
                          <a class="tel" href="tel:+213770171795">(+213) 770 24 24 45 </a><br />
                          <a class="tel" href="tel:+213770171795">(+213) 540 71 23 93 </a>
                        </div>
                        <div className='contact_2'>
                        <h5>Email</h5>
                        <a class="mailto" href="mailto:contact@devgate.net">contact@devgate.net</a>
                        </div>
      
                    </div>
                    <div className='contact_3'>
                    <a className='icon_round fb' href='https://wwww.facebook.com/devgatealgeria'>
                            <FaFacebookF/>
                        </a>
                        <a className='icon_round in' href='https://www.instagram.com/devgatealgeria/' >
                            <FaInstagram/>
                        </a>
                        <a className='icon_round tw'>
                            <FaTwitter/>
                        </a>
                        <a className='icon_round lk'>
                            <FaLinkedinIn/>
                        </a>
                        </div>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
        <Offcanvas id="Offcanvas_style_global" scroll='true' placement='end' show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body> 
                <div className='section_menu_contect'>
                <Row>
                    <Col>
                    <Nav.Link  href="#Home">Home</Nav.Link>
                    <Nav.Link href="#Services">Services</Nav.Link>
                    <Nav.Link href="#Featured">About us</Nav.Link>
                    <Nav.Link href="#About">Pricing</Nav.Link>
                    <Nav.Link href="#Projects">Projects</Nav.Link>
                    <Nav.Link href="#contact">Contact us</Nav.Link>
                    </Col>
                    <Col>
                    <div className='Offcanvas_contact'>
                        <div className='contact_1'>
                            <h5>Contact information</h5>
                            <p>Alger,Algeria</p>
                        </div>
                        <div className='contact_2'>
                        <h5>We are available 24/ 7. Call Now.</h5>
                          <a class="tel" href="tel:+213770171795"><FaPhone/>(+213) 770 17 17 95 </a>
                          <a class="tel" href="tel:+213770171795"><FaPhone/>(+213) 770 24 24 45 </a>
                          <a class="tel" href="tel:+213770171795"><FaPhone/>(+213) 540 71 23 93 </a>
                          <a class="mailto" href="mailto:contact@devgate.net"><FaEnvelope/>contact@devgate.net</a>
                        </div>
                        <div className='contact_3'>
                        <h5>Follow us</h5>
                        <a className='icon_round fb' href='https://wwww.facebook.com/devgatealgeria'>
                            <FaFacebookF/>
                        </a>
                        <a className='icon_round in' href='https://www.instagram.com/devgatealgeria/' >
                            <FaInstagram/>
                        </a>
                        <a className='icon_round tw'>
                            <FaTwitter/>
                        </a>
                        <a className='icon_round lk'>
                            <FaLinkedinIn/>
                        </a>
                        </div>
                    </div>
                    </Col>
                </Row>
                </div>
    
            </Offcanvas.Body>
        </Offcanvas>
    </StyledDIV>;
};
