import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import im01 from '../images/banner-thumb-5.png';  
import im02 from '../images/bubble-9.png';
import im03 from '../images/line-4.png';

const StyledDIV = styled.div`
    height: 420px;
    img.imag_banner {
    position: absolute;
    top: -170px;
    max-width: 900px;
    right: -60px;
}
.ff_d_conter.row {
    align-items: center;
}
.SectionPages .container{
    position: relative;
    margin-top: 5%;

}
.sq_title {
    margin-top: 10%;
    position: absolute;
}

.sq_title h3 {
    font-size: 64px;
    color: #1e1e1e;
    font-weight: 800;
}
li.shape.shape-1 {
    position: absolute;
    bottom: 0;
    z-index: -1;
}
@media only screen and (max-width: 500px) {
 img.imag_banner {
    position: absolute;
    top: 136px;
    max-width: 906px;
    right: 0;
    width: -webkit-fill-available;
}
.sq_title h3 {
    font-size: 48px;
    color: #1e1e1e;
    font-weight: 800;
}
}


`;

const SectionPages = () => {
  return (
    <StyledDIV>
        <div className='SectionPages'>
        <Container>
        <div className='sq_title'>
                        <h3>Estimate Project</h3>
                        
                    </div>
            <img className='imag_banner' src={im01} alt='bbu'/>
        </Container>
        <ul className="shape-group-6 list-unstyled">
                            <li className="shape shape-1"><img loading="lazy"  src={im02} alt="Bubble"/></li>

              
                            <li className="shape shape-3"><img loading="lazy"  src={im03} alt="Bubble"/></li>
              
        </ul>

        </div>
    </StyledDIV>
  )
}

export default SectionPages